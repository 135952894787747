import { DataViewBase } from '@/components/dataview';
import { EventCard } from '@/components/eventcard';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {GetEvents} from "@/services/events";

export function OrgEvent() {
  const location = useLocation();
  const [page, setPage] = useState(1);

  const dispatch: AppDispatch = useDispatch();
  const {events, paginator} = useSelector((state: any) => state.event);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  useEffect(() => {
    dispatch(GetEvents(searchQuery, page))
  }, [searchQuery, page]);

  return (
    <>
      <div>
        <DataViewBase name='Daftar Event' noun='event' total={paginator.to}
                      currentTotal={paginator.total}
                      currentPage={paginator.current_page}
                      pages={paginator.last_page}
                      onPageRequest={(nextPage) => setPage(nextPage)}>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'>
            {
              events.map((event: any, key: string) => (
                <EventCard data={event} key={key} mode='private' />
              ))
            }
          </div>
        </DataViewBase>
      </div>
    </>
  );
}

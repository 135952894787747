import React from "react";
import {CheckIcon} from "@heroicons/react/24/outline";
import {ExclamationCircleIcon, TicketIcon} from "@heroicons/react/24/solid";
import {FormatCurrency} from "@/routes/root";
import {Tag} from "@/components/tag";
import {HSeparator} from "@/components/separator";
import {Button} from "@/components/button";
import moment from "moment";

export interface TicketCardProps {
  ticket: any,
  mode?: 'private' | 'public';
  isCurrentTicket?: boolean;
  onTicketClicked?: (id: string) => void;
}

export const TicketCard: React.FC<TicketCardProps> = ({
  ticket,
  mode = 'public',
}) => {
  const isPrivate = mode === 'private';

  return (
      <div className="w-full rounded overflow-hidden border border-gray-300">
        <div className="bg-purple-700 text-white px-4 py-2 h-[80px] flex justify-between items-center">
          <div className="flex items-center">
            <div className="bg-white text-purple-700 rounded-full p-2 mr-2">
              <TicketIcon className='h-[20px] w-[20px] text-purple-500' />
            </div>
            <span className="font-semibold">{ticket.name}</span>
          </div>
          <div>
            <div>
              <span className="text-white font-bold">
                <FormatCurrency amount={ticket.price} />
              </span>
            </div>
              {ticket.is_sold &&
            <div className={'flex justify-end'}>
              <Tag color='danger'>Habis</Tag>
            </div>
              }
          </div>
        </div>
        <div className="px-4 py-4 bg-white">
            <p className={'list-disc pl-4 text-gray-600 whitespace-pre-wrap'}>{ticket.description ?? "Tidak ada keterangan"}</p>
        </div>
          <div>
            <HSeparator />
            <div className="px-4 py-3 bg-white">
                {ticket.is_open && TicketAlreadyOpen(isPrivate)}
                {(ticket.is_waiting_open && TicketWillOpen(ticket))}
                {((!ticket.is_waiting_open && ticket.is_sold) && TicketAlreadyClosed())}
            </div>
          </div>
      </div>
  );
};

const TicketWillOpen = (ticket: any) => {
    return (
        <div className={'flex justify-between'}>
            <div className={'flex gap-1'}>
                <ExclamationCircleIcon className='h-[20px] w-[20px] my-auto text-red-500' />
                <span className="font-semibold">
                    Tiket akan segera dijual
                </span>
            </div>
            <div>
                <span className="font-semibold text-red-600">{moment(ticket.start_sell_at).fromNow()}</span>
            </div>
        </div>
    );
}

const TicketAlreadyClosed = () => {
    return (
        <div className={'flex justify-between'}>
            <div className={'flex gap-1'}>
                <ExclamationCircleIcon className='h-[20px] w-[20px] my-auto text-red-500' />
                <span className="font-semibold">
                    Tiket sudah selesai/habis terjual
                </span>
            </div>
        </div>
    );
}

const TicketAlreadyOpen = (isPrivate: boolean) => {
    return (
        <div className={'flex justify-between'}>
            <div className={'flex gap-1 my-auto'}>
                <CheckIcon className='h-[20px] w-[20px] my-auto text-green-500' />
                <span className="font-semibold">
                        Tiket masih bisa dibeli
                    </span>
            </div>
            {
                isPrivate &&
                <div>
                    <Button look={'outline'} variant={'neutral'}>Pilih</Button>
                </div>
            }
        </div>
    );
}

import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {setOnSuccessSubmit} from "@/reducers/events";
import {tickets, loading, getErrors} from "@/reducers/tickets";

export const UpdateTicketInformation = (eventId: string, attributes: {
    tickets: any,
    channels: any,
    customer_data: any,
}):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/org/events/'+ eventId +'/tickets',
            attributes,
            {
            headers: headers()
        }).then((response) => {
            dispatch(setOnSuccessSubmit('ticket-updated'))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetTickets = (slug?: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/events/'+ slug +'/tickets',
            {
            headers: headers()
        }).then((response) => {
            dispatch(tickets(response.data.data))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

import { Button } from '@/components/button';
import { DataTable } from '@/components/table/datatable';
import { Tag } from '@/components/tag';
import { EyeIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import moment from "moment";
import {useLocation} from "react-router-dom";
import {GetOrganizers} from "@/services/organizer";

interface Transaction {
  id: string;
  name: string;
  email: string;
  username: string;
  phone: string;
  country: {
    name: string
    flags: string
  };
  created_at: string;
}

const columns: ColumnDef<Transaction>[] = [
  {
    accessorKey: 'name',
    header: 'Nama',
  },
  {
    accessorKey: 'username',
    header: 'Username',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'phone',
    header: 'No. Telepon',
  },
  {
    accessorKey: 'country',
    header: 'Negara',
    cell: ({ row }) => {
      const country = row.original.country;
      return <><img className={'w-12'} src={country?.flags} alt={country?.name}/></>;
    },
  },
  {
    accessorKey: 'created_at',
    header: 'Tanggal Registrasi',
    cell: ({ row }) => {
      const transactionDate = row.original.created_at;
      return moment(transactionDate).format('lll');
    },
  },
  {
    header: 'Aksi',
    cell: ({ row }) => {
      return (
          <Button element={'link'} to={row.original.username} variant='neutral' size='sm' leftIcon={<EyeIcon />}>
            Detail
          </Button>
      );
    },
  },
];

export function Organizers() {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(1);

  const {organizers, paginator} = useSelector((state: any) => state.organizer);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  useEffect(() => {
    dispatch(GetOrganizers(searchQuery, page))
  }, [searchQuery, page]);

  return (
    <>
      <DataTable
        name='Data Organizer'
        noun='organizer'
        columns={columns}
        data={organizers}
        total={paginator.to}
        currentTotal={paginator.total}
        currentPage={paginator.current_page}
        pages={paginator.last_page}
        onPageRequest={(nextPage) => setPage(nextPage)}
      />
    </>
  );
}

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    organizers: [],
    paginator: [],
    exportRoute: '',
    loading: false,
    errorMsg: '',
    errors: {}
};

const organizersSlice = createSlice({
    name: 'organizer',
    initialState,
    reducers: {
        setOrganizers: (state, action) => {
            state.organizers = action.payload;
        },
        setPaginator: (state, action) => {
            state.paginator = action.payload;
        },
        setExportRoute: (state, action) => {
            state.exportRoute = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, setOrganizers, setPaginator, setExportRoute, clearErrors, getErrors } = organizersSlice.actions;
export default organizersSlice.reducer;

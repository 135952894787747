import React, {useEffect, useState} from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {CheckIcon} from "@heroicons/react/24/solid";
import {useDispatch, useSelector} from "react-redux";
import {login, clearErrors, getLoginGoogle, loginGoogleCallback} from "@/reducers/auth"
import {AppDispatch} from "@/root/store";
import {useSearchParams} from "react-router-dom";

export function Login() {
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    const scope = searchParams.get('scope');
    const authUser = searchParams.get('authuser');
    const prompt = searchParams.get('prompt');

    const dispatch: AppDispatch = useDispatch();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const { loading, errorMsg } = useSelector((state: any) => state.auth);

    useEffect(() => {
        if(code) {
            dispatch(loginGoogleCallback(code, scope, authUser, prompt))
        }
    }, [code, scope, authUser, prompt, dispatch])

    const submit = (e: any) => {
        e.preventDefault();

        dispatch(login(formData.username, formData.password))
    }

    const setData = (e: any, element: string) => {
        setFormData({...formData, [element]: e.target.value})

        dispatch(clearErrors());
    }

    const loginGoogleSubmit = () => {
        dispatch(getLoginGoogle())
    }

    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Halo, apa kabar team? 👋🏻</div>
            <p className={"text-400 mb-4"}>Sudahkah kamu dipush artha hari ini?</p>
            <form onSubmit={submit}>
                <div>
                    <div className="mb-3">
                        <FormLabel label='Username/Email' errorText={errorMsg} required>
                            <Input type='text' onChange={(e) => setData(e, 'username')} required/>
                        </FormLabel>
                    </div>
                    <div className="mb-3">
                        <FormLabel label='Password' required>
                            <Input type='password' onChange={(e) => setData(e, 'password')} required/>
                        </FormLabel>
                    </div>

                    <div>
                        <Button
                            loading={loading === 'manual'}
                            type={'submit'}
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Masuk</Button>
                        {/*<Button
                            type={'button'}
                            onClick={loginGoogleSubmit}
                            loading={loading === 'google'}
                            variant={'neutral'}
                            look={'outline'}
                            className={'w-full mt-2'}
                            color='neutral'
                            leftIcon={<i className="pi pi-google"></i>}
                        >Masuk dengan Google</Button>*/}
                    </div>
                </div>
            </form>
        </>
    )
}

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { SectionItem, SectionTabs } from '../_nav/section';

const sections: Record<string, string> = {
    info: 'Informasi',
    security: 'Password',
    event: 'Event'
};

export function OrganizerDetailLayout() {
    const location = useLocation();

    const pathArray = location.pathname.split('/').slice(3);
    const section = pathArray[0];

    return !section ? (
        <Navigate to='info' />
    ) : (
        <>
            <SectionTabs>
                {Object.entries(sections).map(([key, value]) => {
                    return (
                        <SectionItem
                            id={key}
                            key={key}
                            name={value}
                            isCurrent={section === key}
                        />
                    );
                })}
            </SectionTabs>
            <Outlet />
        </>
    );
}

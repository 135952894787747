import { Button } from '@/components/button';
import { HSeparator, VSeparator } from '@/components/separator';
import { Tag } from '@/components/tag';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  ClipboardDocumentIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  CalendarDaysIcon,
  CheckBadgeIcon,
  ClockIcon, DocumentCheckIcon, HandRaisedIcon,
  MapPinIcon,
  TicketIcon, XMarkIcon,
} from '@heroicons/react/24/solid';
import React, {useEffect, useState} from 'react';
import {ApproveEvent, DeleteEvent, RejectEvent, ShowEvent} from "@/services/events";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import moment from "moment";
import {TicketListModal} from "@/routes/org/event/_ticket/ticketlistmodal";
import {Modal} from "@/components/modal";
import {setOnSuccessSubmit} from "@/reducers/events";
import toast from "react-hot-toast";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ label, children }) => {
  return (
    <div className='flex flex-col gap-4'>
      <h1 className='text-2xl font-semibold'>{label}</h1>
      {children}
    </div>
  );
};

export function OrgEventDetailInfo() {
  const [showTicket, setShowTicket] = useState(false)
  const [showDeleteEvent, setShowDeleteEvent] = useState(false)
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();
  const {event, onEventDeleted, onSuccessSubmit} = useSelector((state: any) => state.event);

  const DeleteEventSubmit = (eventId: string) => {
    dispatch(DeleteEvent(eventId))
  }

  const ApproveEventSubmit = (eventId: string) => {
    dispatch(ApproveEvent(eventId))
  }

  const RejectEventSubmit = (eventId: string) => {
    dispatch(RejectEvent(eventId))
  }

  useEffect(() => {
    if(onEventDeleted) {
      window.location.href = '/event';
    }
  }, [onEventDeleted]);

  useEffect(() => {
    dispatch(ShowEvent(slug))
  }, [dispatch, slug, onSuccessSubmit]);

  return (
    <>
      <Modal
          show={showDeleteEvent}
          onRequestClose={() => setShowDeleteEvent(false)}
          title='Hapus event?'
          body={
            <>
              <p>Apakah kamu yakin akan menghapus event ini?</p>
            </>
          }
          footer={
            <div className='flex justify-end gap-3'>
              <Button
                  variant='neutral'
                  onClick={() => setShowDeleteEvent(false)}
                  leftIcon={<XMarkIcon />}
              >
                Tidak
              </Button>
              <Button
                  variant='danger'
                  onClick={() => {
                    DeleteEventSubmit(event.id)
                  }}
                  leftIcon={<TrashIcon />}
              >
                Iya, Hapus
              </Button>
            </div>
          }
      />
      <TicketListModal show={showTicket} onRequestClose={() => {setShowTicket(!showTicket)}} tickets={event.tickets} />
      <div className='flex flex-col gap-2 sm:flex-row sm:flex-wrap sm:items-center'>
        <h1 className='text-lg font-medium sm:flex-grow'>Detail Event</h1>
        <Button variant='danger' look='outline' onClick={() => {
          setShowDeleteEvent(true)
        }} leftIcon={<TrashIcon />}>
          Hapus event
        </Button>
        {event.state !== "Berakhir" && <div className='flex flex-col gap-2 sm:flex-row sm:flex-wrap sm:items-center'>
          <Button onClick={() => {
            RejectEventSubmit(event.id)
          }} variant={'danger'} leftIcon={<HandRaisedIcon />}>Reject event</Button>
          <Button onClick={() => {
            ApproveEventSubmit(event.id)
          }} variant={'success'} leftIcon={<CheckIcon />}>Approve event</Button>
        </div>}
      </div>
      <div className='grid lg:grid-cols-5 gap-6'>
        <div className={'lg:col-span-3'}>
          <img
            className='h-full w-full rounded-md object-fill'
            src={event.banner.fallback_url || '/tmp/1200x900.png'}
            srcSet={event.banner.srcset}
            alt={event.title}
          />
        </div>
        <div className='lg:col-span-2 shrink-0 basis-[429px] flex-col gap-5 rounded-md border-[1px] border-neutral-40 p-5'>
          <div className='flex items-start gap-2'>
            {event.location && <Tag color='danger'>{event.location?.type}</Tag>}
            <Tag color='neutral'>{event.type}</Tag>
            <Tag color={event?.status.color}>{event?.status.label}</Tag>
            <Tag color={'warning'}>{event?.state}</Tag>
          </div>
          <h1 className='max-line-1 sm:max-line-2 my-4 flex-shrink-0 overflow-hidden text-base font-medium sm:text-xl sm:font-semibold'>
            {event.title}
          </h1>
          <HSeparator />
          <div className='flex flex-col gap-3'>
            <div className='flex items-center mt-2'>
              <p className='mr-auto text-sm font-medium'>Pilih tiket</p>
            </div>

            <div className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3 cursor-pointer' onClick={() => {
              setShowTicket(true)
            }}>
              <TicketIcon className='h-[20px] w-[20px] text-primary' />
              <p className='text-sm font-medium'>Lihat tiket</p>
            </div>

            <p className='mr-auto text-sm font-medium'>Atur jumlah tiket</p>
            <div className='flex items-center gap-3'>
              <div className='flex items-start gap-1 self-start rounded-md bg-neutral-40 p-2 text-sm text-neutral-60'>
                <span className='h-[20px]'>-</span>
                <span className='w-[31px] text-center'>1</span>
                <span className='h-[20px]'>+</span>
              </div>
            </div>
            <HSeparator />
            {/*<div className='flex rounded-md border-[1px] border-primary'>
               <TicketIcon className='h-[20px] w-[20px] text-primary' />
              <div className='bg-primary p-3'>
                <TicketIcon className='h-[20px] w-[20px]' />
              </div>
              <div className='flex w-full p-3'>
                <p className='mr-auto text-sm font-medium'>
                  Daftar voucher tersedia
                </p>
                <ChevronRightIcon className='h-[20px] w-[20px] self-center' />
              </div>
            </div>*/}
            <div className='flex items-center gap-1'>
              <p className='mr-auto text-base font-medium'>Subtotal</p>
              <p className='text-base font-medium text-danger'>Rp 0</p>
              <ChevronDownIcon className='h-[16px] w-[16px] self-center' />
            </div>
            <Button leftIcon={<CheckBadgeIcon />} disabled>
              Beli tiket
            </Button>
          </div>
        </div>
      </div>
      <HSeparator />
      <Section label='Detail Acara'>
        <div className='flex flex-col gap-3'>
          <div className='flex items-center gap-2'>
            <MapPinIcon className='w-[20px] shrink-0 grow-0 text-neutral-60' />
            <span className='shrink-0 text-lg font-normal'>
              {event.location?.name}
            </span>
          </div>
          <div className='flex flex-wrap gap-2'>
            <div className='flex items-center gap-2'>
              <CalendarDaysIcon className='w-[20px] text-neutral-60' />
              <span className='text-lg font-normal'>{moment(event.start_at).format('ll')} - {moment(event.end_at).format('ll')}</span>
            </div>
            {/*<VSeparator />*/}
            {/*<div className='flex items-center gap-2'>
              <ClockIcon className='w-[20px] text-neutral-60' />
              <span className='text-lg font-normal'>{moment(event.start_at).format('LT')}</span>
            </div>*/}
          </div>
        </div>
      </Section>
      <Section label='Deskripsi'>
        <div className='text-base font-normal text-neutral-70'>
          <p className={'whitespace-pre-wrap'}>
            {event.description}
          </p>
        </div>
      </Section>
      <Section label='Syarat & Ketentuan'>
        <div className='text-base font-normal text-neutral-70'>
          <p className={'whitespace-pre-wrap'}>{event.terms}</p>
        </div>
      </Section>
    </>
  );
}

import { HSeparator } from '@/components/separator';
import { Tag } from '@/components/tag';
import {
  CalendarDaysIcon,
  MapPinIcon,
  TicketIcon,
} from '@heroicons/react/24/solid';
import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {ShowTransaction} from "@/services/transactions";
import moment from "moment";
import {FormatCurrency} from "@/routes/root";

const Section: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  return <div className='flex flex-col gap-4'>{children}</div>;
};

const Row: React.FC<{ label: React.ReactNode; value: React.ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <div className='flex flex-col items-start gap-2 text-sm sm:flex-row'>
      <span className='font-medium text-neutral-70 sm:flex-grow'>{label}</span>
      {value}
    </div>
  );
};

export function OrganizerDetailInfo() {
  const {id} = useParams();
  const dispatch: AppDispatch = useDispatch();

  const {transaction} = useSelector((state: any) => state.transaction);

  useEffect(() => {
    dispatch(ShowTransaction(id))
  }, [dispatch, id]);

  return (
    <>
      <h2 className='text-center text-lg font-medium'>Detail Transaksi</h2>
      <Section>
        <Row
          label='Status pembayaran'
          value={<Tag color={transaction.status.color}>{transaction.status.label}</Tag>}
        />
        <Row
          label='No. Referensi'
          value={<span className='text-info'>{transaction.reference}</span>}
        />
        <Row label='Tanggal transaksi' value={moment(transaction.date).format('LL')} />
      </Section>
      <HSeparator />
      <Section>
        <Row label='Pembeli' value={transaction.buyer.name} />
        <Row label='E-mail' value={transaction.buyer.email} />
        <Row label='No. Telepon' value={transaction.buyer.whatsapp} />
      </Section>
      <HSeparator />
      <h1 className='text-2xl font-medium'>Detail event</h1>
      <div className='flex flex-col gap-6 sm:flex-row'>
        <img
          className='h-[184px] w-full flex-shrink-0 rounded-md object-cover sm:h-[290px] sm:w-[290px]'
          src={transaction.event.banner.fallback_url}
          srcSet={transaction.event.banner.srcset}
          alt={transaction.event.title}
        />
        <div className='flex flex-grow flex-col gap-6'>
          <div className='flex flex-wrap items-start gap-2'>
            <Tag color='info'>{transaction.event.type}</Tag>
            <Tag>{transaction.event.location.type}</Tag>
          </div>
          <h1 className='text-xl font-semibold sm:min-h-[60px]'>
            {transaction.event.title}
          </h1>
          <div className='flex gap-4'>
            <div className='flex flex-grow flex-col gap-2'>
              <p className='text-sm font-medium'>Tiket terpilih</p>
              <div className='flex gap-3 rounded-md border-[1px] border-neutral-40 p-3'>
                <TicketIcon className='h-[20px] w-[20px] text-primary' />
                <p className='text-sm font-medium'>{transaction.ticket} - <FormatCurrency amount={transaction.subtotal / transaction.quantity} currency={transaction.currency_code} /></p>
              </div>
            </div>
            {/*  */}
            <div className='flex flex-col gap-2'>
              <p className='text-sm font-medium'>Jumlah tiket</p>
              <div className='rounded-md border-[1px] border-neutral-40 p-3'>
                <p className='text-center text-sm font-medium'>{transaction.quantity}</p>
              </div>
            </div>
          </div>
          <div className='flex items-center gap-2'>
            <MapPinIcon className='w-[20px] shrink-0 grow-0 text-neutral-60' />
            <span className='max-line-1 h-[20px] w-[calc(80%)] shrink-0 text-sm font-medium'>
              {transaction.event.location.name}
            </span>
          </div>
          <div className='flex gap-2'>
            <div className='flex items-center gap-2'>
              <CalendarDaysIcon className='w-[20px] text-neutral-60' />
              <span className='text-sm font-medium'>
                {moment(transaction.event.start_at).format('LL')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <HSeparator />
      <h1 className='text-2xl font-medium'>Rincian pembayaran</h1>
      <Section>
        <Row label='Metode pembayaran' value={transaction.channel_name} />
        <Row
          label='Total harga tiket'
          value={<span className='font-medium text-danger'>
            <FormatCurrency amount={transaction.subtotal} currency={transaction.currency_code} />
          </span>}
        />
        <HSeparator />
        <Row
          label={
            <span className='text-base font-medium text-neutral-100'>
              Subtotal
            </span>
          }
          value={
            <span className='text-base font-medium text-danger'>
              <FormatCurrency amount={transaction.subtotal} currency={transaction.currency_code} />
            </span>
          }
        />
      </Section>
    </>
  );
}

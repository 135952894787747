import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    documents,
    events,
    EventsInterface,
    getErrors,
    loading,
    setChannels,
    setCustomerData,
    setEvent,
    setOnEventCreated,
    setOnEventDeleted,
    setOnSuccessSubmit, setPaginator,
    setTickets, setTicketScanned
} from "@/reducers/events";
import moment from "moment";
import toast from "react-hot-toast";

export const GetEvents = (query?: string | null, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/events?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(events(response.data.data));
            dispatch(setPaginator(response.data.meta))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetEventDocuments = (eventCode?: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/events/' + eventCode + '/documents', {
            headers: headers()
        }).then((response) => {
            dispatch(documents(response.data.data));
            dispatch(setPaginator(response.data.meta));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const ShowEvent = (code?: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/events/' + code, {
            headers: headers()
        }).then((response) => {
            dispatch(setEvent(response.data.data));
            dispatch(setTickets(response.data.data.tickets));
            dispatch(setChannels(response.data.data.channels));
            dispatch(setCustomerData(response.data.data.customer_data));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const UpdateEventInformation = (eventId: string, data: EventsInterface):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/org/events/' + eventId + '/information', {
            title: data.title,
            description: data.description,
            terms: data.terms,
            start_at: moment(data.start_at).format('YYYY-MM-DD HH:mm'),
            end_at: moment(data.end_at).format('YYYY-MM-DD HH:mm'),
            type: data.type,
            locations: {
                name: data.location?.name,
                url: data.location?.url,
                type: data.location?.type
            },
        }, {
            headers: headers()
        }).then((response) => {
            dispatch(setOnSuccessSubmit('information-updated'));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const ApproveEvent = (eventId: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/employee/events/' + eventId + '/approve', {}, {
            headers: headers()
        }).then((response) => {
            dispatch(setOnSuccessSubmit('event-approved'));

            toast.success('Event berhasil diapprove');
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const RejectEvent = (eventId: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/employee/events/' + eventId + '/reject', {}, {
            headers: headers()
        }).then((response) => {
            dispatch(setOnSuccessSubmit('event-rejected'));

            toast.success('Event berhasil ditolak');
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const UploadBanner = (eventId: string, image: File):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/events/' + eventId + '/banner', {
            image: image
        }, {
            headers: {...headers(), "Content-Type": "multipart/form-data"}
        }).then((response) => {
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const CreateEvent = (title: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .post(process.env.REACT_APP_BASE_URL + '/org/events', {
            title: title
        }, {
            headers: headers()
        }).then((response) => {
            dispatch(setOnEventCreated(response.data.data.code))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const DeleteEvent = (eventId: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .delete(process.env.REACT_APP_BASE_URL + '/employee/events/' + eventId, {
            headers: headers()
        }).then((response) => {
            dispatch(setOnEventDeleted());

            toast.success('Event berhasil dihapus');
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const PublishEvent = (eventId: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .patch(process.env.REACT_APP_BASE_URL + '/org/events/' + eventId + '/publish', {},{
            headers: headers()
        }).then((response) => {
            dispatch(setOnSuccessSubmit('event-published'));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const ScanTicket = (qrCode: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .post(process.env.REACT_APP_BASE_URL + '/org/events/scan-ticket', {
            qr_code: qrCode
        }, {
            headers: headers()
        }).then((response) => {
            dispatch(setTicketScanned(response.data.data))

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

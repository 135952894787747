import { Button } from '@/components/button';
import { DataTable } from '@/components/table/datatable';
import { EyeIcon } from '@heroicons/react/24/solid';
import { ColumnDef } from '@tanstack/react-table';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {GetEventDocuments} from "@/services/events";
import {ThemeColor} from "@/lib/utils";

interface Transaction {
  id: string;
  filename: string;
  type: string;
  filesize: string;
  file_url: string;
}

const columns: ColumnDef<Transaction>[] = [
  {
    header: 'Nama',
    accessorKey: 'filename',
  },
  {
    header: 'Tipe Dokumen',
    accessorKey: 'type',
  },
  {
    accessorKey: 'filesize',
    header: 'Ukuran File (KB)'
  },
  {
    header: 'Aksi',
    cell: ({row}) => {
      return (
        <Button element={'link'} target={'_blank'} to={row.original.file_url} variant='neutral' size='sm' leftIcon={<EyeIcon />}>
          Detail
        </Button>
      );
    },
  },
];

export function OrgEventDetailDocument() {
  const {slug} = useParams();
  const dispatch: AppDispatch = useDispatch();

  const {documents, paginator} = useSelector((state: any) => state.event);

  useEffect(() => {
    dispatch(GetEventDocuments(slug))
  }, [slug])

  return (
    <>
      <DataTable name='Daftar Dokumen' noun='dokumen' columns={columns} data={documents}
                 total={paginator.to}
                 currentTotal={paginator.total}
                 currentPage={paginator.current_page}
                 pages={paginator.last_page} />
    </>
  );
}
